import { createReducer, on } from '@ngrx/store';
import { anomalies } from '.';
import { AnomalyStream } from '../../services/anomalies.service';

export type Severity = 'minor' | 'major' | 'critical' | null;

export type Filters = {
  dateRange: { from: Date | null; through: Date | null };
  hidden: boolean | null;
  confirmed: boolean | null;
  instanceId: string | null;
  severity: 'minor' | 'major' | 'critical' | null;
};

export type Pagination = { page: number; pageSize: number };

export type State = {
  anomalyStream: AnomalyStream | null;
  filters: Filters;
  pagination: Pagination;
};

export const initialState: State = {
  anomalyStream: null,
  filters: {
    dateRange: { from: null, through: null },
    hidden: false,
    confirmed: null,
    instanceId: null,
    severity: null,
  },
  pagination: { page: 1, pageSize: 10 },
};

export const reducer = createReducer(
  initialState,
  on(anomalies.actions.loadAnomalyStream, (state) => ({ ...state, anomalyStream: null })),
  on(anomalies.actions.loadAnomalyStreamSuccess, (state, { anomalyStream }) => ({
    ...state,
    anomalyStream,
  })),
  on(anomalies.actions.changeFilters, (state, { filters }) => ({ ...state, filters })),
  on(anomalies.actions.resetFilters, (state) => ({ ...state, filters: initialState.filters })),
  on(anomalies.actions.changePagination, (state, { pagination }) => ({ ...state, pagination })),
  on(anomalies.actions.resetPagination, (state) => ({
    ...state,
    pagination: initialState.pagination,
  })),
);

export const key = 'anomalies';
