import { StateType } from '..';

export const selectTransactions = (state: StateType) => state.transactions.transactions;

export const selectFilters = (state: StateType) => state.transactions.filters;

export const selectPagination = (state: StateType) => state.transactions.pagination;

export const selectSorting = (state: StateType) => state.transactions.sorting;

export const selectAccountOverview = (state: StateType) => state.transactions.account;

export const selectAccountBalance = (state: StateType) => state.transactions.overallBalance;
