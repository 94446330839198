import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, TitleStrategy } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ClipboardModule } from 'ngx-clipboard';
import { BASE_PATH } from '../../generated/api';
import { environment } from '../environments/environment';
import { FakeAPIService } from './_fake';
import { routes } from './app.routes';
import { AuthenticationService } from './services/authentication.service';
import { CustomAdapter } from './shared/custom-date-adapter';
import { CustomDateParserFormatter } from './shared/custom-date-formatter';
import { localeIdProvider } from './shared/custom-providers/locale-id.provider';
import { CustomTitleStrategy } from './shared/custom-title.strategy';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { alerts } from './store/alerts';
import { anomalies } from './store/anomalies';
import { auth } from './store/auth';
import { entities } from './store/entities';
import { objects } from './store/objects';
import { tenants } from './store/tenants';
import { transactions } from './store/transactions';
import { users } from './store/users';

import { locale } from './store/locale';

export const appConfig: ApplicationConfig = {
  providers: [
    localeIdProvider,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: BASE_PATH,
      useValue: environment.apiBasePath,
    },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(),
    importProvidersFrom(
      TranslateModule.forRoot({}),
      ClipboardModule,
      // #fake-start#
      environment.isMockEnabled
        ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
            passThruUnknownUrl: true,
            dataEncapsulation: false,
          })
        : [],
      // #fake-end#
      InlineSVGModule.forRoot(),
      SweetAlert2Module.forRoot(),
    ),
    { provide: TitleStrategy, useClass: CustomTitleStrategy },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    provideAnimations(),
    AuthenticationService,
    provideStore(),
    provideState({ name: tenants.key, reducer: tenants.reducer }),
    provideState({ name: auth.key, reducer: auth.reducer }),
    provideState({ name: anomalies.key, reducer: anomalies.reducer }),
    provideState({ name: transactions.key, reducer: transactions.reducer }),
    provideState({ name: users.key, reducer: users.reducer }),
    provideState({ name: locale.key, reducer: locale.reducer }),
    provideState({ name: entities.key, reducer: entities.reducer }),
    provideState({ name: objects.key, reducer: objects.reducer }),
    provideState({ name: alerts.key, reducer: alerts.reducer }),
    provideEffects(
      auth.effects,
      tenants.effects,
      transactions.effects,
      users.effects,
      entities.effects,
      objects.effects,
      anomalies.effects,
      locale.effects,
    ),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
  ],
};
