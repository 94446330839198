import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { DefaultApiService, UsersPost201Response } from '../../../generated/api';

export interface User {
  id: string;
  email: string;
  phone: string | null;
  firstName: string | null;
  lastName: string | null;
  roles: string[] | null;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apiService: DefaultApiService) {}

  private unpackApiResponse(apiResponse: UsersPost201Response): User | null {
    return apiResponse.data ?? null;
  }

  findUserById(id: string) {
    return this.apiService.usersIdGet({ id }).pipe(map((response) => response.data));
  }

  findAllUsersByTenantId(tenantId: string): Observable<User[]> {
    return this.apiService
      .usersGet({ tenantId })
      .pipe(map((response) => response.data.map((user) => user)));
  }

  createUser(email: string, tenantId: string) {
    return this.apiService
      .usersPost({ usersPostRequest: { email, tenantId } })
      .pipe(map(this.unpackApiResponse));
  }

  deleteUser(userId: string, tenantId: string): Observable<null> {
    // TODO:
    console.log('NOT IMPLEMENTED YET!!! => ', 'Delete User: ', userId, ' => Tenant: ', tenantId);
    return of(null);
  }

  updateUser(id: string, tenantId: string, user: Omit<User, 'id' | 'email'>): Observable<User> {
    return this.apiService
      .usersIdPut({ id: id, usersIdPutRequest: { ...user, tenantId } })
      .pipe(map((response) => response.data));
  }
}
