import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, Observable, of } from 'rxjs';
import { DefaultApiService } from '../../../generated/api';
import { objects } from '../store/objects';

export interface DataObject {
  dataId: string;
  entityCount: number;
  earliestImport: string;
  latestImport: string;
  payload?: any;
}
export interface DataObjects {
  items: DataObject[] | null;
  total: number | null;
}
export interface SortingStateObjects {
  sortField?: 'dataId' | 'entityCount' | 'earliestImport' | 'latestImport';
  sortDirection?: 'asc' | 'desc';
}

@Injectable({
  providedIn: 'root',
})
export class ObjectsService {
  constructor(private apiService: DefaultApiService) {}

  findDataObjects(options: {
    tenantId: string;
    filters?: objects.Filters;
    pagination?: { page?: number; pageSize?: number };
    sorting?: SortingStateObjects;
  }): Observable<DataObjects> {
    return this.apiService
      .objectsGet({
        tenantId: options.tenantId,
        dataIds: options.filters?.dataId ? [options.filters?.dataId] : undefined,
        fromEarliestImport: this.dateFromToStr(options.filters?.dateRangeFirst.fromDate),
        throughEarliestImport: this.dateThroughToStr(options.filters?.dateRangeFirst.toDate),
        fromLatestImport: this.dateFromToStr(options.filters?.dateRangeLast.fromDate),
        throughLatestImport: this.dateThroughToStr(options.filters?.dateRangeLast.toDate),
        page: options.pagination?.page,
        pageSize: options.pagination?.pageSize,
        sortDirection: options.sorting?.sortDirection,
        sortField: options.sorting?.sortField,
      })
      .pipe(
        map((response) => response.data),
        catchError(() => of({ total: 0, items: [] })),
      );
  }

  private dateFromToStr(date?: NgbDate | null) {
    const DELIMITER = '-';
    return date
      ? date.year.toString().padStart(4, '0') +
          DELIMITER +
          date.month.toString().padStart(2, '0') +
          DELIMITER +
          date.day.toString().padStart(2, '0') +
          'T00:00:00.000Z'
      : undefined;
  }
  private dateThroughToStr(date?: NgbDate | null) {
    const DELIMITER = '-';
    return date
      ? date.year.toString().padStart(4, '0') +
          DELIMITER +
          date.month.toString().padStart(2, '0') +
          DELIMITER +
          date.day.toString().padStart(2, '0') +
          'T23:59:59.000Z'
      : undefined;
  }
}
