import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { exhaustMap, map } from 'rxjs';
import { locale } from '.';
import { StateType } from '..';
import { TranslationService } from '../../modules/i18n';
import { locale as deLang } from '../../modules/i18n/vocabs/de';
import { locale as enLang } from '../../modules/i18n/vocabs/en';
import { selectAvailableLocales } from './locale.selectors';

type ShouldReuseRoute = (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) => boolean;

// TODO:
// Führt momentan zum Problem mit dem Routing.
// Wird bearbeiten wenn das Routing in den Store aufgenommen wird

// export const reloadOnLocaleChange = createEffect(
//   (store$ = inject(Store<StateType>), router = inject(Router)) =>
//     store$
//       .select(locale.selectors.selectLocale)
//       .pipe
//       concatMap(async () => {
//         console.log(router.config);
//         const { shouldReuseRoute } = router.routeReuseStrategy;

//         const reuseStrat = router.routeReuseStrategy.shouldReuseRoute;
//         // router.routeReuseStrategy.shouldReuseRoute = () => false;
//         router.navigated = false;
//         await router.navigateByUrl(router.url).catch(noop);
//         router.routeReuseStrategy.shouldReuseRoute = reuseStrat;
//       }),
//       (),
//   {
//     functional: true,
//     dispatch: false,
//   },
// );

export const updateTranslateServiceOnLocaleChange = createEffect(
  (store$ = inject(Store<StateType>), translate = inject(TranslateService)) =>
    store$.select(locale.selectors.selectLocale).pipe(
      map((newLocale) => {
        translate.use(newLocale.lang);
      }),
    ),
  { functional: true, dispatch: false },
);

export const updateTranslationServiceOnLocaleChange = createEffect(
  (store$ = inject(Store<StateType>), translate = inject(TranslationService)) =>
    store$.select(locale.selectors.selectLocale).pipe(
      map((newLocale) => {
        translate.setLanguage(newLocale.lang);
      }),
    ),
  { functional: true, dispatch: false },
);

export const loadTranslations = createEffect(
  (actions$ = inject(Actions), translationService = inject(TranslationService)) =>
    actions$.pipe(
      ofType(locale.actions.loadTranslations),
      map(() => translationService.loadTranslations(enLang, deLang)),
    ),
  { functional: true, dispatch: false },
);

export const changeDefaultLocale = createEffect(
  (
    actions$ = inject(Actions),
    store$ = inject(Store<StateType>),
    translate = inject(TranslateService),
  ) =>
    actions$.pipe(
      ofType(locale.actions.initializeLocale),
      exhaustMap(() => {
        return store$.select(selectAvailableLocales).pipe(
          map((availableLocales) => {
            const browserLocale = availableLocales.find(
              (l) => l.lang === translate.getBrowserLang(),
            );

            const fallBack = locale.initialState.locale;

            return locale.actions.changeLocale({ locale: browserLocale ?? fallBack });
          }),
        );
      }),
    ),
  { functional: true },
);
