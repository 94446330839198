import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { DefaultApiService } from '../../../generated/api';

@Injectable({
  providedIn: 'root',
})
export class AccountBalanceService {
  constructor(private apiService: DefaultApiService) {}

  findAccountBalance(tenantId: string) {
    return this.apiService.balancesTenantIdGet({ tenantId }).pipe(map((response) => response.data));
  }
}
