import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, exhaustMap, filter, first, map, of } from 'rxjs';
import { users } from '.';
import { StateType } from '..';
import { UsersService } from '../../services/users.service';
import { alerts } from '../alerts';
import { tenants } from '../tenants';

export const loadAvailableUsers = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService), store = inject(Store)) =>
    actions$.pipe(
      ofType(
        users.actions.loadAvailableUsers,
        users.actions.deleteUserFromSelectedTenantSuccess,
        users.actions.updateUserSuccess,
        users.actions.createUserSuccess,
      ),
      exhaustMap(() =>
        store.select(tenants.selectors.selectSelectedTenant).pipe(
          filter((tenant) => tenant !== null),
          first(),
          exhaustMap(({ id }) =>
            usersService.findAllUsersByTenantId(id).pipe(
              map((result) => users.actions.loadAvailableUsersSuccess({ availableUsers: result })),
              catchError(() => of(users.actions.loadAvailableUsersFailure())),
            ),
          ),
        ),
      ),
    ),
  { functional: true },
);

export const deleteUserFromSelectedTenant = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService), store$ = inject(Store)) =>
    actions$.pipe(
      ofType(users.actions.deleteUserFromSelectedTenant),
      exhaustMap(({ userId }) =>
        store$.select(tenants.selectors.selectSelectedTenant).pipe(
          filter((tenant) => tenant !== null),
          first(),
          exhaustMap((tenant) =>
            usersService.deleteUser(userId, tenant.id).pipe(
              map(() => users.actions.deleteUserFromSelectedTenantSuccess()),
              catchError(() => of(users.actions.deleteUserFromSelectedTenantFailure())),
            ),
          ),
        ),
      ),
    ),
  { functional: true },
);
// Effects for showing delete alerts
export const showDeleteSuccessAlert = createEffect(
  (actions$ = inject(Actions), translate = inject(TranslateService)) =>
    actions$.pipe(
      ofType(users.actions.deleteUserFromSelectedTenantSuccess),
      map(() =>
        alerts.actions.showAlert({
          icon: 'success',
          title: translate.instant('SWEETALERT.SUCCESS_TITLE'),
          text: translate.instant('SWEETALERT.DELETE_USER_MODAL.SUCCESS_TEXT'),
        }),
      ),
    ),
  { functional: true },
);

export const showDeleteFailureAlert = createEffect(
  (actions$ = inject(Actions), translate = inject(TranslateService)) =>
    actions$.pipe(
      ofType(users.actions.deleteUserFromSelectedTenantFailure),
      map(() =>
        alerts.actions.showAlert({
          icon: 'error',
          title: translate.instant('SWEETALERT.ERROR_TITLE'),
          text: translate.instant('SWEETALERT.DELETE_USER_MODAL.ERROR_TEXT'),
        }),
      ),
    ),
  { functional: true },
);

export const updateUser = createEffect(
  (actions$ = inject(Actions), usersService = inject(UsersService), store$ = inject(Store)) =>
    actions$.pipe(
      ofType(users.actions.updateUser),
      exhaustMap(({ userId, data }) =>
        store$.select(tenants.selectors.selectSelectedTenant).pipe(
          filter((tenant) => tenant !== null),
          first(),
          exhaustMap((tenant) =>
            usersService
              .updateUser(userId, tenant.id, {
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
                roles: data.roles,
              })
              .pipe(
                map((user) => users.actions.updateUserSuccess({ user })),

                catchError(() => of(users.actions.updateUserFailure())),
              ),
          ),
        ),
      ),
    ),
  { functional: true },
);
// Effects for showing update alerts
export const showUpdateSuccessAlert = createEffect(
  (actions$ = inject(Actions), translate = inject(TranslateService)) =>
    actions$.pipe(
      ofType(users.actions.updateUserSuccess),
      map(() =>
        alerts.actions.showAlert({
          icon: 'success',
          title: translate.instant('SWEETALERT.SUCCESS_TITLE'),
          text: translate.instant('SWEETALERT.EDIT_USER_MODAL.SUCCESS_TEXT'),
        }),
      ),
    ),
  { functional: true },
);

export const showUpdateFailureAlert = createEffect(
  (actions$ = inject(Actions), translate = inject(TranslateService)) =>
    actions$.pipe(
      ofType(users.actions.updateUserFailure),
      map(() =>
        alerts.actions.showAlert({
          icon: 'error',
          title: translate.instant('SWEETALERT.ERROR_TITLE'),
          text: translate.instant('SWEETALERT.EDIT_USER_MODAL.ERROR_TEXT'),
        }),
      ),
    ),
  { functional: true },
);

export const createUser = createEffect(
  (
    actions$ = inject(Actions),
    usersService = inject(UsersService),
    store$ = inject(Store<StateType>),
  ) =>
    actions$.pipe(
      ofType(users.actions.createUser),
      exhaustMap(({ email }) =>
        store$.select(tenants.selectors.selectSelectedTenant).pipe(
          first(),
          filter((tenant) => tenant !== null),
          exhaustMap((tenant) =>
            usersService.createUser(email, tenant.id).pipe(
              map(() => users.actions.createUserSuccess()),
              catchError(() => of(users.actions.createUserFailure())),
            ),
          ),
        ),
      ),
    ),
  { functional: true },
);
// Effects for showing create alerts
export const showCreateSuccessAlert = createEffect(
  (actions$ = inject(Actions), translate = inject(TranslateService)) =>
    actions$.pipe(
      ofType(users.actions.createUserSuccess),
      map(() =>
        alerts.actions.showAlert({
          icon: 'success',
          title: translate.instant('SWEETALERT.SUCCESS_TITLE'),
          text: translate.instant('SWEETALERT.CREATE_USER_MODAL.SUCCESS_TEXT'),
        }),
      ),
    ),
  { functional: true },
);

export const showCreateFailureAlert = createEffect(
  (actions$ = inject(Actions), translate = inject(TranslateService)) =>
    actions$.pipe(
      ofType(users.actions.createUserFailure),
      map(() =>
        alerts.actions.showAlert({
          icon: 'error',
          title: translate.instant('SWEETALERT.ERROR_TITLE'),
          text: translate.instant('SWEETALERT.CREATE_USER_MODAL.ERROR_TEXT'),
        }),
      ),
    ),
  { functional: true },
);
