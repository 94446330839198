import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { createReducer, on } from '@ngrx/store';
import { objects } from '.';
import { DataObjects } from '../../services/objects.service';
import { thirtyDaysAgo, today } from '../../shared/utils/custom-date-utils';

export type AlertIcon = 'success' | 'error';

export type Alert = { icon: AlertIcon; title: string; text: string };

export type SortField = 'dataId' | 'entityCount' | 'earliestImport' | 'latestImport' | null;

export type SortDirection = 'asc' | 'desc' | null;

export type Filters = {
  dateRangeFirst: {
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
  };
  dateRangeLast: {
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
  };
  dataId: string | null;
};

export type Pagination = {
  page: number | null;
  pageSize: number | null;
};

export type Sorting = {
  sortField: SortField | null;
  sortDirection: SortDirection | null;
};

export type State = {
  dataObjects: DataObjects;
  pagination: Pagination;
  filters: Filters;
  sorting: Sorting;
};

export const initialState: State = {
  dataObjects: {
    items: null,
    total: null,
  },
  pagination: {
    page: 1,
    pageSize: 10,
  },
  filters: {
    dateRangeFirst: {
      fromDate: thirtyDaysAgo(),
      toDate: today(),
    },
    dateRangeLast: {
      fromDate: thirtyDaysAgo(),
      toDate: today(),
    },
    dataId: null,
  },
  sorting: {
    sortField: null,
    sortDirection: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(objects.actions.loadDataObjectsSuccess, (state, { dataObjects }) => ({
    ...state,
    dataObjects,
  })),
  on(objects.actions.changeFilters, (state, { filters }) => ({ ...state, filters })),
  on(objects.actions.changePagination, (state, { pagination }) => ({ ...state, pagination })),
  on(objects.actions.changeSorting, (state, { sorting }) => ({ ...state, sorting })),
  on(objects.actions.resetFilters, (state) => ({ ...state, filters: initialState.filters })),
);

export const key = 'dataObjects';
