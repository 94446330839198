import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DefaultApiService } from '../../../generated/api';
import { anomalies } from '../store/anomalies';

export type Anomaly = {
  id: string;
  confirmed: boolean | null;
  hidden: boolean;
  detectedAt: string;
  entities: AnomalyEntity[];
  severity: string;
  type: string;
  instanceName: string;
  instanceId: string;
};

export type AnomalyStream = {
  tenantId: string;
  length: number;
  anomalies: Anomaly[];
};

export type PatchAnomalyData = {
  confirmed?: boolean | null;
  hidden?: boolean;
};

export type AnomalyEntity = {
  id: string;
  payload?: any;
};

@Injectable({
  providedIn: 'root',
})
export class AnomaliesService {
  constructor(private apiService: DefaultApiService) {}

  findAnomalyStream(
    tenantId: string,
    filters: anomalies.Filters,
    pagination: anomalies.Pagination,
  ): Observable<AnomalyStream> {
    return this.apiService
      .streamsTenantIdGet({
        tenantId,
        page: pagination.page ?? undefined,
        pageSize: pagination.pageSize ?? undefined,
        from: filters.dateRange.from?.toISOString() ?? undefined,
        through: filters.dateRange.through?.toISOString() ?? undefined,
        hidden: filters.hidden ?? undefined,
        confirmed: filters.confirmed ?? undefined,
        instanceId: filters.instanceId ?? undefined,
        severity: filters.severity ?? undefined,
      })
      .pipe(map((response) => response.data));
  }

  patchAnomaly(tenantId: string, id: string, data: PatchAnomalyData): Observable<Anomaly> {
    return this.apiService
      .streamsTenantIdAnomaliesIdPatch({
        tenantId,
        id,
        anomalyStreamsTenantIdAnomaliesIdPatchRequest: data,
      })
      .pipe(map((result) => result.data));
  }
}
