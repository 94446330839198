import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
// language list
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ScriptsInitComponent } from './_metronic/layout/components/scripts-init/scripts-init.component';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { AlertsComponent } from './shared/components/alerts/alerts.component';
import { StateType } from './store';
import { locale } from './store/locale';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, ScriptsInitComponent, AlertsComponent, TranslateModule],
})
export class AppComponent implements OnInit {
  constructor(
    private modeService: ThemeModeService,
    private store$: Store<StateType>,
  ) {
    this.store$.dispatch(locale.actions.loadTranslations());
    this.store$.dispatch(locale.actions.initializeLocale());
  }

  ngOnInit() {
    this.modeService.init();
  }
}
