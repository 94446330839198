import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Amplify } from 'aws-amplify';
import {
  AuthUser,
  SignInOutput,
  SignUpOutput,
  confirmUserAttribute,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  sendUserAttributeVerificationCode,
  signIn,
  signOut,
  signUp,
  updatePassword,
  updateUserAttributes,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { BehaviorSubject, Observable, Subject, filter, map, mergeMap, of, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { StateType } from '../store';
import { auth } from '../store/auth';

export interface User extends AuthUser {
  attributes: UserAttributes;
}

export interface UserAttributes {
  email: string;
  email_verified: string;
  sub: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  #currentAuthUser$: BehaviorSubject<AuthUser | null | undefined> = new BehaviorSubject<
    AuthUser | null | undefined
  >(undefined);

  currentUser$: Observable<User | null> = this.#currentAuthUser$.pipe(
    mergeMap((authUser) => {
      if (authUser === null || authUser === undefined) return of(null);

      return this.attributes().then((attributes) => ({
        ...authUser,
        attributes: {
          email: attributes.email!,
          email_verified: attributes.email_verified!,
          sub: attributes.sub!,
        },
      }));
    }),
  );

  isAuthenticated$: Observable<boolean> = this.#currentAuthUser$.pipe(
    filter((authUser) => authUser !== undefined),
    map((user) => user !== null),
  );

  private destroy$ = new Subject<void>();

  constructor(private store: Store<StateType>) {
    // Synchronize the session from Amplify to the local session state handling
    Hub.listen('auth', (event) => {
      switch (event.payload.event) {
        case 'signedIn':
          this.#currentAuthUser$.next(event.payload.data);
          break;
        case 'signedOut':
          this.#currentAuthUser$.next(null);
          break;
        default:
      }
    });

    // Configure Amplify
    this.#configureAmplify();

    // Restore user, if existing
    getCurrentUser().then(
      (user) => {
        this.#currentAuthUser$.next(user);
      },
      () => this.#currentAuthUser$.next(null),
    );

    this.#currentAuthUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.store.dispatch(auth.actions.loadCurrentUser()));

    getCurrentUser().then(
      () => {
        this.store.dispatch(auth.actions.loadCurrentUser());
      },
      () => this.#currentAuthUser$.next(null),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public signUp(username: string, password?: string): Promise<SignUpOutput> {
    return signUp({
      username,
      password,
      options: {
        userAttributes: {
          email: username,
        },
      },
    });
  }

  public signIn(username: string, password: string): Promise<SignInOutput> {
    return signIn({
      username,
      password,
    });
  }

  public signOut(global: boolean = false) {
    this.#currentAuthUser$.next(null);
    return signOut({ global });
  }

  public async accessToken() {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      return accessToken;
    } catch (err) {
      return undefined;
    }
  }

  public async idToken() {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      return idToken;
    } catch (err) {
      return undefined;
    }
  }

  public updatePassword(oldPassword: string, newPassword: string) {
    return updatePassword({ newPassword, oldPassword });
  }

  public async attributes() {
    return fetchUserAttributes();
  }

  public updateAttributes(attributes: { [key: string]: string }) {
    return updateUserAttributes({ userAttributes: attributes });
  }

  public async verifyEmail(code: string) {
    return confirmUserAttribute({ userAttributeKey: 'email', confirmationCode: code });
  }

  public async resendVerificationCode() {
    return sendUserAttributeVerificationCode({ userAttributeKey: 'email' });
  }

  #configureAmplify() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolClientId: environment.aws.cognito.webClientId,
          userPoolId: environment.aws.cognito.userPoolId,
          loginWith: { email: true },
        },
      },
    });
  }
}
