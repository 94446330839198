import { createAction, props } from '@ngrx/store';
import { User } from '../../services/users.service';

export const loadAvailableUsers = createAction('[Users] Load Available Users');

export const loadAvailableUsersSuccess = createAction(
  '[Users] Load Available Users Success',
  props<{ availableUsers: User[] }>(),
);

export const loadAvailableUsersFailure = createAction('[Users] Load Available Users Failure');

export const selectUser = createAction(
  '[Users] Select User',
  props<{ selectedUser: User | null }>(),
);

// Create User
export const createUser = createAction('[Users] Create User', props<{ email: string }>());
export const createUserSuccess = createAction('[Users] Create User Success');
export const createUserFailure = createAction('[Users] Create User Failure');

// Update/Edit User
export const updateUser = createAction(
  '[Users] Update User',
  props<{
    userId: string;
    data: {
      firstName: string | null;
      lastName: string | null;
      phone: string | null;
      roles: string[];
    };
  }>(),
);
export const updateUserSuccess = createAction(
  '[Users] Update User Success',
  props<{ user: User }>(),
);
export const updateUserFailure = createAction('[Users] Update User Failure');

// Delete User for Given TenantId
export const deleteUserFromSelectedTenant = createAction(
  '[Users] Delete User From Selected Tenant',
  props<{ userId: string }>(),
);
export const deleteUserFromSelectedTenantSuccess = createAction(
  '[Users] Delete User From Selected Tenant Success',
);
export const deleteUserFromSelectedTenantFailure = createAction(
  '[Users] Delete User From Selected Tenant Failure',
);
