import { createAction, props } from '@ngrx/store';
import { locale } from '.';

export const changeLocale = createAction(
  '[Locale] Change Locale',
  props<{ locale: locale.Locale }>(),
);

export const loadTranslations = createAction('[Locale] Load Translations');

export const initializeLocale = createAction('[Locale] Initialize Locale');
