import { inject } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isEqual from 'lodash.isequal';
import { catchError, combineLatest, distinctUntilChanged, exhaustMap, filter, map, of } from 'rxjs';
import { entities } from '.';
import { StateType } from '..';
import { EntitiesService } from '../../services/entities.service';
import { tenants } from '../tenants';

export const loadDataEntities = createEffect(
  (
    entitiesService = inject(EntitiesService),
    store: Store<StateType> = inject(Store<StateType>),
  ) => {
    return combineLatest([
      store.select(tenants.selectors.selectSelectedTenant).pipe(filter((t) => t !== null)),
      store.select(entities.selectors.selectFilters),
      store.select(entities.selectors.selectPagination),
      store.select(entities.selectors.selectSorting),
    ]).pipe(
      distinctUntilChanged(isEqual),
      exhaustMap(([selectedTenant, filters, pagination, sorting]) =>
        entitiesService
          .findDataEntities({
            tenantId: selectedTenant.id,
            filters: filters,
            pagination: pagination,
            sorting: sorting,
          })
          .pipe(
            map((result) => entities.actions.loadDataEntitiesSuccess({ dataEntities: result })),
            catchError(() => of(entities.actions.loadDataEntitiesFailure())),
          ),
      ),
    );
  },
  { functional: true },
);
