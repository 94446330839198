import { LOCALE_ID, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import { StateType } from '../../store';
import { locale } from '../../store/locale';

export class LocaleId extends String {
  private locale: string;

  constructor(private store$: Store<StateType>) {
    super();
    this.store$
      .select(locale.selectors.selectLocale)
      .subscribe((locale) => (this.locale = locale.lang));
  }

  toString() {
    return this.locale;
  }

  valueOf() {
    return this.toString();
  }
}

export const localeIdProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [Store],
};
