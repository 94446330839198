import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, exhaustMap, filter, first, map, of } from 'rxjs';
import { anomalies } from '.';
import { StateType } from '..';
import { AnomaliesService } from '../../services/anomalies.service';
import { tenants } from '../tenants';

export const loadAnomalyStream = createEffect(
  (
    actions$ = inject(Actions),
    store$ = inject(Store<StateType>),
    anomaliesService = inject(AnomaliesService),
  ) =>
    actions$.pipe(
      ofType(
        anomalies.actions.loadAnomalyStream,
        anomalies.actions.changePagination,
        anomalies.actions.changeFilters,
        anomalies.actions.resetFilters,
        anomalies.actions.resetPagination,
        anomalies.actions.patchAnomalySuccess,
      ),
      exhaustMap(() =>
        combineLatest([
          store$.select(tenants.selectors.selectSelectedTenant).pipe(filter((t) => t !== null)),
          store$.select(anomalies.selectors.selectFilters),
          store$.select(anomalies.selectors.selectPagination),
        ]).pipe(
          first(),
          exhaustMap(([tenant, filters, pagination]) =>
            anomaliesService.findAnomalyStream(tenant.id, filters, pagination).pipe(
              map((anomalyStream) => anomalies.actions.loadAnomalyStreamSuccess({ anomalyStream })),
              catchError(() => of(anomalies.actions.loadAnomalyStreamFailure)),
            ),
          ),
        ),
      ),
    ),
  { functional: true },
);

export const patchAnomaly = createEffect(
  (
    actions$ = inject(Actions),
    store$ = inject(Store<StateType>),
    anomaliesService = inject(AnomaliesService),
  ) =>
    actions$.pipe(
      ofType(anomalies.actions.patchAnomaly),
      exhaustMap(({ anomalyId, data }) =>
        store$.select(tenants.selectors.selectSelectedTenant).pipe(
          filter((t) => t !== null),
          first(),
          exhaustMap(({ id }) =>
            anomaliesService.patchAnomaly(id, anomalyId, data).pipe(
              map((anomaly) => anomalies.actions.patchAnomalySuccess({ anomaly })),
              catchError(() => of(anomalies.actions.patchAnomalyFailure)),
            ),
          ),
        ),
      ),
    ),
  { functional: true },
);
