import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, Observable, of } from 'rxjs';
import { DefaultApiService } from '../../../generated/api';
import { transactions } from '../store/transactions';

export interface Transaction {
  id: string;
  type: string;
  agent: string;
  description: string | null;
  timestamp: number;
  amount: number;
  tenantId: string;
}

export interface Transactions {
  items: Transaction[];
  total: number;
}
export interface SortingStateBilling {
  sortField?: 'timestamp' | 'type' | 'amount' | 'agent';
  sortDirection?: 'asc' | 'desc';
}

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private apiService: DefaultApiService) {}

  findTransactions(
    tenantId: string,
    filters: transactions.Filters,
    pagination: { page?: number; pageSize?: number },
    sorting: SortingStateBilling,
  ): Observable<Transactions> {
    const formatDate = (date?: NgbDate) =>
      date
        ? `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`
        : undefined;

    return this.apiService
      .transactionsGet({
        tenantId,
        agents: filters.agents ?? undefined,
        types: filters.transactionTypes ?? undefined,
        fromTimestamp: filters?.dateRange?.fromDate
          ? formatDate(filters?.dateRange?.fromDate)
          : undefined,
        throughTimestamp: filters?.dateRange?.toDate
          ? formatDate(filters?.dateRange?.toDate)
          : undefined,
        page: pagination.page ?? undefined,
        pageSize: pagination.pageSize ?? undefined,
        sortDirection: sorting.sortDirection ?? undefined,
        sortField: sorting.sortField ?? undefined,
      })
      .pipe(
        map((response) => response.data),
        catchError(() => of({ total: 0, items: [] })),
      );
  }
}
