import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SwalComponent, SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { skip, Subject, takeUntil } from 'rxjs';
import { SweetAlertOptions } from 'sweetalert2';
import { StateType } from '../../../store';
import { alerts } from '../../../store/alerts';

@Component({
  selector: 'app-alerts',
  standalone: true,
  imports: [SweetAlert2Module],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss',
})
export class AlertsComponent {
  @ViewChild('noticeSwal')
  noticeSwal!: SwalComponent;

  swalOptions: SweetAlertOptions = {};

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private store$: Store<StateType>,
    private cdr: ChangeDetectorRef,
  ) {
    this.store$
      .select(alerts.selectors.selectShowAlert)
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe((showAlert) => {
        if (showAlert !== null) {
          this.showAlert(showAlert.icon, showAlert.title, showAlert.text);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private showAlert(icon: 'error' | 'success', title: string, text: string) {
    this.swalOptions = {
      buttonsStyling: false,
      confirmButtonText: `${this.translate.instant('SWEETALERT.CONFIRM')}`,
      allowOutsideClick: false,
      customClass: {
        confirmButton: `btn btn-${icon === 'error' ? 'danger' : 'success'}`,
      },
      title: `<b>${title}</b>`,
      text,
      icon,
    };

    this.cdr.detectChanges();

    this.noticeSwal.fire();
  }
}
