import { createReducer, on } from '@ngrx/store';
import { locale } from '.';

export interface Locale {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

export type State = {
  availableLocales: Locale[];
  locale: Locale;
};

export const initialState: State = {
  availableLocales: [
    {
      lang: 'en',
      name: 'English',
      flag: '../../../../../assets/media/flags/united-states.svg',
    },
    {
      lang: 'de',
      name: 'German',
      flag: '../../../../../assets/media/flags/germany.svg',
    },
  ],
  locale: {
    lang: 'en',
    name: 'English',
    flag: '../../../../../assets/media/flags/united-states.svg',
  },
};

export const reducer = createReducer(
  initialState,
  on(locale.actions.changeLocale, (state, { locale }) => ({ ...state, locale })),
);

export const key = 'locale';
