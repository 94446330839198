// Germany
export const locale = {
  lang: 'de',
  data: {
    AMPLIFY: {
      'Sign In': 'Anmelden',
      'Sign in': 'Anmelden',
      'Sign in to your account': 'Welcome Back!',
      Email: 'E-Mail',
      'Enter your Email': 'E-Mail Adresse',
      'Enter your Password': 'Passwort',
      Password: 'Passwort',
      'Confirm Password': 'Passwort bestätigen',
      'Forgot your password?': 'Passwort vergessen?',
      'Reset Password': 'Passwort zurücksetzen',
      'Forgot your password': 'Passwort vergessen?',
      'Create Account': 'Registrieren',
      'User does not exist.':
        'Es konnte kein Account zu der angegebenen E-Mail Adresse gefunden werden.',
      'An account with the given email already exists.':
        'Für diese E-Mail Adresse existiert bereits ein Account.',
      'Password did not conform with policy: Password not long enough':
        'Das Passwort erfüllt nicht die Mindestlänge (8 Zeichen).',
      'Password did not conform with policy: Password must have numeric characters':
        'Das Passwort muss mindestens eine Zahl beinhalten.',
      'Password did not conform with policy: Password must have symbol characters':
        'Das Passwort muss mindestens ein Sonderzeichen enthalten.',
      'Your passwords must match': 'Die Passwörter stimmen nicht überein.',
      'Sign In with Google': 'Login mit Google',
      'Sign In with Facebook': 'Login mit Facebook',
      'Sign In with Apple': 'Login mit Apple',
      'Sign In with Amazon': 'Login mit Amazon',
      'Sign Up with Google': 'Anmelden mit Google',
      'Sign Up with Facebook': 'Anmelden mit Facebook',
      'Sign Up with Apple': 'Anmelden mit Apple',
      'Sign Up with Amazon': 'Anmelden mit Amazon',
      'Invalid lambda function output : Invalid JSON':
        'Es ist ein interner Fehler aufgetreten. Bitte wenden Sie sich an den Support.',
      'Incorrect username or password.': 'E-Mail Adresse und Passwort sind nicht korrekt.',
      'We Emailed You': 'E-Mail Adresse bestätigen',
      'It may take a minute to arrive. ': 'Es kann einen Moment dauern, bis die E-Mail eintrifft.',
      'Your code is on the way. To log in, enter the code we emailed to':
        'Der Verifikationscode wurde versendet an ',
      'We Sent A Code': 'E-Mail Adresse bestätigen',
      'Your code is on the way. To log in, enter the code we sent you':
        'Der Verifikationscode wurde bereits an Sie versendet. Bitte geben Sie den erhalten Verifikationscode ein',
      'It may take a minute to arrive': 'Es kann einen Moment dauern, bis dieser bei Ihnen ankommt',
      'User cannot be confirmed. Current status is CONFIRMED':
        'Die Verifikation konnte nicht durchgeführt werden, da der Account bereits verifiziert wurde.',
      'Reset your password': 'Passwort zurücksetzen',
      'Enter your username': 'E-Mail Adresse',
      'Change Password': 'Passwort ändern',
      'Back to Sign In': 'Zurück zum Login',
      'Please confirm your Password': 'Passwort wiederholen',
      Confirm: 'Bestätigen',
      'Resend Code': 'Verifikationscode zusenden',
      'Send code': 'Bestätigen',
      'Invalid verification code provided, please try again.':
        'Der eingegebene Verifikationscode ist ungültig. Bitte erneut versuchen.',
      'Cannot reset password for the user as there is no registered/verified email or phone_number':
        'Das Zurücksetzen des Passwortes konnte nicht durchgeführt werden. Bitte wenden Sie sich an den Support.',
      'Enter your email': 'E-Mail Adresse eingeben',
      'Password attempts exceeded':
        'Anzahl der zulässigen Versuche überschritten. Bitte probieren Sie es zu einem späteren Zeitpunkt noch einmal.',
      'Attempt limit exceeded, please try after some time.':
        'Anzahl der zulässigen Versuche überschritten. Bitte probieren Sie es zu einem späteren Zeitpunkt noch einmal.',
      'Enter your Code': 'Code eingeben',
      'Enter your code': 'Code eingeben',
      'Confirmation Code': 'Verifikationscode',
      'We Texted You': 'Verifikationscode wurde versendet',
      'New Password': 'Neues Passwort',
      Submit: 'Absenden',
      Skip: 'Überspringen',
      'Account recovery requires verified contact information':
        'Bitte bestätigen Sie Ihre E-Mail Adresse',
      Verify: 'Verifizieren',
    },
    TRANSLATOR: {
      SELECT: 'Wähle deine Sprache',
    },
    PAGETITLE: {
      DASHBOARD: 'Dashboard',
      ANOMALY: 'Anomalie Stream',
      ACCOUNT: 'Account',
      OVERVIEW: 'Übersicht',
      SETTINGS: 'Einstellungen',
      BILLING: 'Abrechnung & Tokens',
      PERMISSIONS: 'Berechtigungen & Rollen',
      USERMANAGE: 'Benutzerverwaltung',
      PROFILEUSER: 'Benutzer',
      INSTANCES: 'Instanzen',
      DATAVIEWER: 'Dataviewer',
    },
    MENU: {
      NEW: 'Neu',
      ACTIONS: 'Aktionen',
      CREATE_POST: 'Erstellen Sie einen neuen Beitrag',
      PAGES: 'Pages',
      FEATURES: 'Eigenschaften',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    SIDEBAR: {
      DATASOURCE: 'Datenquellen',
      PAGES: 'Seiten',
      INSTANCES: 'Instanzen',
    },
    NAVBAR: {
      PROFILE: 'Mein Profil',
      ACCOUNT: 'Account',
      BILLING_TOKEN: 'Abrechnung & Tokens',
      LANGUAGE: 'Sprache',
      SIGNOUT: 'Abmelden',
    },
    AUTH: {
      GENERAL: {
        OR: 'Oder',
        SUBMIT_BUTTON: 'einreichen',
        NO_ACCOUNT: 'Hast du kein Konto?',
        SIGNUP_BUTTON: 'Anmelden',
        FORGOT_BUTTON: 'Passwort vergessen',
        BACK_BUTTON: 'Zurück',
        PRIVACY: 'Privatsphäre',
        LEGAL: 'Rechtliches',
        CONTACT: 'Kontakt',
      },
      LOGIN: {
        TITLE: 'Anmelden',
        SUBTITLE: '...und Unregelmäßigkeiten finden',
        SIGNUPGOOGLE: 'Anmelden mit Google',
        SIGNUPAPPLE: 'Anmelden mit Apple',
        OR_TEXT: 'oder mit Ihrer Email',
        WRONGLOGIN: 'Die Anmeldedaten sind falsch',
        PW_LABEL: 'Passwort',
        BUTTON: 'Weiter',
        NO_MEMBER: 'Noch keinen Account?',
        SIGNUP: 'Jetzt anfragen',
      },
      FORGOT: {
        TITLE: 'Passwort vergessen?',
        DESC: 'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.',
        SUCCESS: 'Ihr Konto wurde erfolgreich zurückgesetzt.',
      },
      REGISTER: {
        TITLE: 'Registrieren',
        SUBTITLE: '...und Unregelmäßigkeiten finden',
        DESC: 'Geben Sie Ihre Daten ein, um Ihr Konto zu erstellen',
        SUCCESS: 'Ihr Konto wurde erfolgreich registriert.',
        REG_INCORRECT: 'Die Registrierungsdaten sind falsch',
        LABEL_FIRSTNAME: 'Vorname',
        LABEL_LASTNAME: 'Nachname',
        LABEL_PHONE: 'Telefon',
        LABEL_COMPANY: 'Firma',
        LABEL_PW: 'Passwort',
        LABEL_CONFIRM_PW: 'Passwort beatätigen',
        NO_MATCH_PW: "'Passswort' und 'Passwort bestätigen' stimmen nicht überein.",
        ACCEPT_TERMS: 'Ich akzeptiere die ',
        TERMS: 'AGB',
        BTN_SUBMIT: 'Absenden',
        HAVE_ACCOUNT: 'Sie haben bereits einen Zugang?',
        SIGN_IN: 'Anmelden',
        WAIT: 'Bitte warten',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Vollständiger Name',
        PASSWORD: 'Passwort',
        CONFIRM_PASSWORD: 'Passwort bestätigen',
        USERNAME: 'Nutzername',
      },
      VALIDATION: {
        INVALID: '{{name}} ist nicht gültig',
        REQUIRED: '{{name}} ist erforderlich',
        MIN_LENGTH: '{{name}} Mindestlänge beträgt {{min}}',
        AGREEMENT_REQUIRED: 'Das Akzeptieren der Geschäftsbedingungen ist erforderlich',
        NOT_FOUND: 'Der angeforderte {{name}} wird nicht gefunden',
        INVALID_LOGIN: 'Die Anmeldedaten sind falsch',
        REQUIRED_FIELD: 'Erforderliches Feld',
        MIN_LENGTH_FIELD: 'Minimale Feldlänge:',
        MAX_LENGTH_FIELD: 'Maximale Feldlänge:',
        INVALID_FIELD: 'Feld ist nicht gültig',
      },
      LINKS: {
        TERMS: 'AGB',
        IMPRINT: 'Impressum',
        CONTACT: 'anolab',
      },
    },
    ACCOUNT: {
      TITLE: 'Berechtigungen & Rollen',
      BALANCE: 'Aktuelle Tokens',
      TOTAL: 'Anzahl Benutzer mit dieser Rolle:',
      OVERVIEW: 'Übersicht',
      SETTINGS: 'Einstellungen',
      BILLING: 'Abrechnung & Tokens',
      PERMS: 'Berechtigungen & Rollen',
      USER: 'Benutzerverwaltung',
      BILLINGTAP: {
        SEARCH: 'Suchen',
        DATE: 'Datum',
        NODATE: 'letzten 30 Tage',
        TOOLTIPDATE: 'Datum löschen',
        TRANSACTIONTYPE: 'Transaktionstyp',
        AMOUNT: 'Betrag',
        AGENT: 'Agent',
        FILTER: 'Filter',
        DATEHINT: 'Falls Sie kein Datum angeben, werden die letzten 30 Tage angezeigt.',
        ALL: 'alle',
        RESET: 'Zurücksetzen',
        APPLY: 'Anwenden',
        OVERVIEW: 'Übersicht',
        BALANCE: 'Saldo',
        CURRENTTOKENS: 'Aktuelle Tokens',
        CONTINGENT: 'Kontigent',
        USAGE: 'Verbrauch',
        SETTLED: 'Abgerechnet',
        TRANSACTIONS: 'Transaktionen',
        NOTRANSACTIONS: 'Keine Transaktionen gefunden',
        DATETIME: 'Datum / Zeit',
        TOKENAMOUNT: 'Tokenmenge',
        PAGINATION: {
          PREVIOUS: 'Vorherige',
          NEXT: 'Nächste',
        },
        TRANSACTIONTYPES: {
          ADJUSTMENT: 'Adjustment',
          USAGE: 'Usage',
          BILLING: 'Billing',
          QUOTA: 'Quota',
          ANY: 'Alle Transaktionstypen',
        },
        AGENTS: {
          DATAFLOW: 'Data Flow',
          DATASTORAGE: 'Data Storage',
          WATCHER: 'Watcher',
          ANY: 'Alle Agents',
        },
      },
      PERMISSIONS: {
        TOTAL: 'Benutzer mit dieser Rolle:',
        CREATE: 'Erstellen',
        VIEW: 'Ansehen',
        EDIT: 'Bearbeiten',
        NEW_ROLE: 'Neue Rolle hinzufügen',
      },
      USER_MANAGE: {
        SEARCH: 'Benutzer suchen',
        ADD_USER: 'Benutzer hinzufügen',
        EDIT: 'Bearbeiten',
        DELETE: 'Entfernen',
        USER: 'Benutzer',
        ROLE: 'Rolle',
        ACTIONS: 'Aktionen',
        EMPTY: 'Keine Benutzer gefunden',
        SELECTROLES: 'Wählen Sie eine oder mehrere Rollen aus',
      },
      DELETE_MODAL: {
        TITLE: 'Lösche',
        TEXT1: 'Wollen Sie den Benutzer ',
        TEXT2: ' wirklich löschen?',
        BTN_CONFIRM: 'Löschen',
        BTN_DISMISS: 'Abbrechen',
      },
      EDIT_MODAL: {
        TITLE: 'Benutzer bearbeiten ',
        FIRSTNAME: 'Vorname',
        LASTNAME: 'Nachname',
        ROLE: 'Benutzer Rolle(n)',
        SELECT: 'Rolle/n wählen',
        EDIT: 'Bearbeite Benutzer Rolle(n)',
        ABORTEDIT: 'Benutzer Rolle(n) bearbeiten abbrechen',
        BTN_SAVE: 'Speichern',
        BTN_CANCEL: 'Abbrechen',
      },
      CREATE_MODAL: {
        TITLE: 'Neuen Benutzer erstellen',
        BTN_SAVE: 'Einladung senden',
        BTN_CANCEL: 'Abbrechen',
      },
    },
    PROFILE: {
      USER_DETAILS: {
        TITLE: 'Profil Details',
        EMAIL: 'Email Adresse',
        FULLNAME: 'Vor- & Nachname',
        ROLE: 'Rolle',
        USER: 'Benutzer',
      },
    },
    DATAVIEWER: {
      TAB: {
        OBJECTS: 'Objekte',
        ENTITIES: 'Entitäten',
      },
      TABLE: {
        IMPORT: 'Importiert',
        IMPORT_ADD: '(Datum & Uhrzeit)',
        COUNTER: 'Anzahl Entitäten',
      },
      PAYLOAD_MODAL: {
        HEADER: 'Payload',
        BTN_CLOSE: 'Schließen',
      },
      NO_DATA: 'Keine Daten vorhanden',
    },
    FILTERMENU: {
      VISIBILITY: 'Sichtbarkeit',
      VISIBLE: 'Sichtbar',
      UNVISIBLE: 'Unsichtbar',
      STATE: 'Status',
      CONFIRMED: 'Bestätigt',
      UNCONFIRMED: 'nicht bestätigt',
      IMPORT: 'Importiert',
      IMPORT_ADD: '(Datum)',
      FIRST_IMPORT: 'Erster Import',
      LAST_IMPORT: 'Letzter Import',
      DEF_ID: 'Definition ID',
      RUN_ID: 'Run ID',
      DATA_ID: 'Data ID',
    },
    SWEETALERT: {
      CONFIRM: 'Verstanden',
      SUCCESS_TITLE: 'Erfolgreich!',
      ERROR_TITLE: 'Fehlgeschlagen!',
      CREATE_USER_MODAL: {
        SUCCESS_TEXT: 'Benutzer wurde angelegt!',
        ERROR_TEXT: 'Benutzer konnte <b class="text-danger">NICHT</b> angelegt werden!',
      },
      EDIT_USER_MODAL: {
        SUCCESS_TEXT: 'Benutzerdaten wurden aktualisiert!',
        ERROR_TEXT: 'Benutzerdaten konnten <b class="text-danger">NICHT</b> aktualisiert werden!',
      },
      DELETE_USER_MODAL: {
        SUCCESS_TEXT: 'Benutzer wurde gelöscht!',
        ERROR_TEXT: 'Benutzer konnte <b class="text-danger">NICHT</b> gelöscht werden!',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Anzahl der ausgewählten Datensätze: ',
        ALL: 'Alle',
        SUSPENDED: 'ausgesetzt',
        ACTIVE: 'Aktiv',
        FILTER: 'Filter',
        BY_STATUS: 'nach Status',
        BY_TYPE: 'nach Type',
        BUSINESS: 'Unternehmen',
        INDIVIDUAL: 'Individuell',
        SEARCH: 'Suche',
        IN_ALL_FIELDS: 'in allen Feldern',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Kunden',
        CUSTOMERS_LIST: 'Kundenliste',
        NEW_CUSTOMER: 'Neuer Kunde',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Kunde löschen',
          DESCRIPTION: 'Sind Sie sicher, dass Sie diesen Kunden endgültig löschen wollen?',
          WAIT_DESCRIPTION: 'Kunde wird gelöscht...',
          MESSAGE: 'Kunde wurde gelöscht',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Kunden löschen',
          DESCRIPTION: 'Sind Sie sicher, dass Sie ausgewählte Kunden dauerhaft löschen möchten?',
          WAIT_DESCRIPTION: 'Kunden werden gelöscht...',
          MESSAGE: 'Ausgewählte Kunden wurden gelöscht',
        },
        UPDATE_STATUS: {
          TITLE: 'Der Status wurde für ausgewählte Kunden aktualisiert',
          MESSAGE: 'Der Status ausgewählter Kunden wurde erfolgreich aktualisiert',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Der Kunde wurde aktualisiert',
          ADD_MESSAGE: 'Der Kunde wurde erstellt',
        },
      },
    },
    INSTANCES: {
      TITLE: 'Meine Instanzen',
      NEW: 'Neue Instanzen',
      EDIT: 'Bearbeiten',
      STATE: {
        CHOOSE: 'Wähle einen Status',
        ACTIVE: 'Aktiv',
        INACTIVE: 'Inaktiv',
      },
      BTN_STATE: {
        GOOD: 'Alles Gut',
        ISSUE: 'Neues Problem',
        STRIKING: 'Auffälig',
      },
      PRODUCTS: 'Produkte',
      ORDERS: 'Bestellungen',
      EMPLOYEES: 'Mitarbeiter',
      PAYMENTS: 'Zahlungen',
      INCOMING: 'Eingehende',
      OUTGOING: 'Ausgehende',
    },
    ANOMALY_STREAM: {
      FILTERS: {
        TITLE: 'Filter',
        DETECTED_AT: 'Erkannt am',
        DETECTED_AT_PLACEHOLDER: 'tt-mm-jjjj',
        VISIBILITY: 'Sichtbarkeit',
        VISIBILITY_OPTIONS: {
          VISIBLE: 'Sichtbar',
          HIDDEN: 'Versteckt',
          ANY: 'Alle',
        },
        SEVERITY: 'Schweregrad',
        SEVERITY_OPTIONS: {
          ANY: 'Alle',
          MINOR: 'Geringfügig',
          MAJOR: 'Schwerwiegend',
          CRITICAL: 'Kritisch',
        },
        STATUS: 'Status',
        STATUS_OPTIONS: {
          ANY: 'Alle',
          CONFIRMED: 'Bestätigt',
          UNCONFIRMED: 'Alle',
          REFUTED: 'Widerrufen',
        },
        INSTANCE_ID: 'Instanz ID',
        APPLY_BUTTON: 'Anwenden',
        RESET_BUTTON: 'Zurücksetzen',
      },
      TABS: {
        DAILY: 'Täglich',
        WEEKLY: 'Wöchentlich',
        MONTHLY: 'Monatlich',
        YEARLY: 'Jährlich',
        TABLE: 'Tabelle',
      },
      COLUMNS: {
        TYPE: 'Typ',
        INSTANCE: 'Instanz',
        SEVERITY: 'Schweregrad',
        DETECTED_AT: 'Erkannt am',
        STATUS: 'Status',
        ENTITIES: 'Entitäten',
        ACTIONS: 'Aktionen',
      },
      SEVERITIES: {
        MINOR: 'Geringfügig',
        MAJOR: 'Schwerwiegend',
        CRITICAL: 'Kritisch',
      },
      FILTER_BUTTON: 'Filter',
      DETAILS_BUTTON: 'Details',
      DETAILS: {
        TITLE: 'Entitäten',
        CLOSE_BUTTON: 'Schließen',
        NO_ENTITIES: 'Die Anomalie referenziert keine Entities',
      },
      STATUS: {
        CONFIRMED: 'Bestätigt',
        UNCONFIRMED: '',
        REFUTED: 'Widerrufen',
      },
      NO_DATA: 'Keine Anomalien vorhanden',
    },
  },
};
