// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18n } from 'aws-amplify/utils';
import { locale as de } from './vocabs/de';
import { locale as en } from './vocabs/en';

export interface Locale {
  lang: string;
  data: object;
}

export const LOCALIZATION_STORAGE_KEY = 'language';
export const DEFAULT_LANGUAGE = 'de';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private langIds: string[] = [];

  constructor(private translate: TranslateService) {
    I18n.putVocabulariesForLanguage('de', de.data.AMPLIFY);
    I18n.putVocabulariesForLanguage('en', en.data.AMPLIFY);

    I18n.setLanguage(
      localStorage.getItem(LOCALIZATION_STORAGE_KEY) || localStorage.getItem(DEFAULT_LANGUAGE)!,
    );

    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
  }

  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      this.translate.setTranslation(locale.lang, locale.data, true);
      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
    this.translate.use(this.getSelectedLanguage());
  }

  setLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem(LOCALIZATION_STORAGE_KEY, lang);
    // window.location.reload();
  }

  getSelectedLanguage(): string {
    return localStorage.getItem(LOCALIZATION_STORAGE_KEY) || this.translate.getDefaultLang();
  }
}
