import { createReducer, on } from '@ngrx/store';
import { users } from '.';
import { User } from '../../services/users.service';

export type State = {
  availableUsers: User[];
};

export const initialState: State = {
  availableUsers: [],
};

export const reducer = createReducer(
  initialState,
  on(users.actions.loadAvailableUsersSuccess, (state, { availableUsers }) => ({
    ...state,
    availableUsers,
  })),
);

export const key = 'users';
