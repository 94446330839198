import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

export function thirtyDaysAgo() {
  const m = moment().subtract(30, 'days');
  return new NgbDate(m.year(), m.month() + 1, m.date());
}

export function today() {
  const m = moment();
  return new NgbDate(m.year(), m.month() + 1, m.date());
}
