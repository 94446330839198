import { inject } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isEqual from 'lodash.isequal';
import { catchError, combineLatest, distinctUntilChanged, exhaustMap, filter, map, of } from 'rxjs';
import { objects } from '.';
import { StateType } from '..';
import { ObjectsService } from '../../services/objects.service';
import { tenants } from '../tenants';

export const loadDataObjects = createEffect(
  (objectsService = inject(ObjectsService), store: Store<StateType> = inject(Store<StateType>)) => {
    return combineLatest([
      store.select(tenants.selectors.selectSelectedTenant).pipe(filter((t) => t !== null)),
      store.select(objects.selectors.selectFilters),
      store.select(objects.selectors.selectPagination),
      store.select(objects.selectors.selectSorting),
    ]).pipe(
      distinctUntilChanged(isEqual),
      exhaustMap(([selectedTenant, filters, pagination, sorting]) =>
        objectsService
          .findDataObjects({
            tenantId: selectedTenant.id,
            filters: filters,
            pagination: pagination,
            sorting: sorting,
          })
          .pipe(
            map((result) => objects.actions.loadDataObjectsSuccess({ dataObjects: result })),
            catchError(() => of(objects.actions.loadDataObjectsFailure())),
          ),
      ),
    );
  },
  { functional: true },
);
