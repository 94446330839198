import { createAction, props } from '@ngrx/store';
import { CurrentUser } from '../../services/current-user.service';

export const loadCurrentUser = createAction('[Auth] Load Current User');

export const loadCurrentUserSuccess = createAction(
  '[Auth] Load Current User Success',
  props<{ currentUser: CurrentUser }>(),
);

export const loadCurrentUserFailure = createAction('[Auth] Load Current User Failure');
