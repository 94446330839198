import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { createReducer, on } from '@ngrx/store';
import { transactions } from '.';

import { AccountOverview } from '../../services/account-overviews.service';
import { Transactions } from '../../services/transactions.service';
import { thirtyDaysAgo, today } from '../../shared/utils/custom-date-utils';

export type SortField = 'timestamp' | 'type' | 'amount' | 'agent' | null;

export type SortDirection = 'asc' | 'desc' | null;

export type Filters = {
  dateRange: {
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
  };
  transactionTypes: string[] | null;
  agents: string[] | null;
};

export type Pagination = {
  page: number | null;
  pageSize: number | null;
};

export type Sorting = {
  sortField: SortField | null;
  sortDirection: SortDirection | null;
};

export type State = {
  transactions: Transactions | null;
  overallBalance: number | null;
  account: AccountOverview;
  pagination: Pagination;
  filters: Filters;
  sorting: Sorting;
};

export const initialState: State = {
  transactions: null,
  overallBalance: null,
  account: {
    tenantId: null,
    balance: null,
    billing: null,
    contingent: null,
    usage: null,
  },
  pagination: {
    page: 1,
    pageSize: 10,
  },
  filters: {
    dateRange: {
      fromDate: thirtyDaysAgo(),
      toDate: today(),
    },
    transactionTypes: ['adjustment', 'usage', 'billing', 'quota'],
    agents: ['data-flow', 'data-storage', 'watcher'],
  },
  sorting: {
    sortField: null,
    sortDirection: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(transactions.actions.changeFilters, (state, { filters }) => ({ ...state, filters })),
  on(transactions.actions.changePagination, (state, { pagination }) => ({ ...state, pagination })),
  on(transactions.actions.changeSorting, (state, { sorting }) => ({ ...state, sorting })),
  on(transactions.actions.resetFilters, (state) => ({ ...state, filters: initialState.filters })),
  on(transactions.actions.loadTransactionsSuccess, (state, { transactions }) => ({
    ...state,
    transactions,
  })),
  on(transactions.actions.loadAccountBalanceSuccess, (state, { balance }) => ({
    ...state,
    overallBalance: balance,
  })),
  on(transactions.actions.loadAccountOverviewSuccess, (state, { account }) => ({
    ...state,
    account: account,
  })),
);

export const key = 'transactions';
