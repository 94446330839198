import { createAction, props } from '@ngrx/store';
import { DataEntities } from '../../services/entities.service';
import { Filters, Pagination, Sorting } from './entities.reducer';

export const loadDataEntities = createAction('[DataEntities] Load DataEntities');

export const loadDataEntitiesSuccess = createAction(
  '[DataEntities] Load DataEntities Success',
  props<{ dataEntities: DataEntities }>(),
);

export const loadDataEntitiesFailure = createAction('[DataEntities] Load DataEntities Failure');

// Filter and Pagination Params
export const changeFilters = createAction(
  '[DataEntities] Change Filters',
  props<{ filters: Filters }>(),
);

export const resetFilters = createAction('[Transactions] Reset Filters');

export const changePagination = createAction(
  '[DataEntities] Change Pagination',
  props<{ pagination: Pagination }>(),
);

export const changeSorting = createAction(
  '[DataEntities] Change Sorting',
  props<{ sorting: Sorting }>(),
);
