import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Injectable()
export class CustomTitleStrategy extends TitleStrategy implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private readonly title: Title,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.translateService
        .get(title)
        .pipe(takeUntil(this.destroy$))
        .subscribe((translatedTitle) => {
          this.title.setTitle(`${translatedTitle} | anolab®`);
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
