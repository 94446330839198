import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { createReducer, on } from '@ngrx/store';
import { entities } from '.';
import { DataEntities } from '../../services/entities.service';
import { thirtyDaysAgo, today } from '../../shared/utils/custom-date-utils';

export type SortField = 'dataflowDefinitionId' | 'dataflowRunId' | 'importedAt' | 'dataId' | null;

export type SortDirection = 'asc' | 'desc' | null;

export type Filters = {
  dateRange: {
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
  };
  defId: string | null;
  runId: string | null;
  dataId: string | null;
};

export type Pagination = {
  page: number | null;
  pageSize: number | null;
};

export type Sorting = {
  sortField: SortField | null;
  sortDirection: SortDirection | null;
};

export type State = {
  dataEntities: DataEntities;
  pagination: Pagination;
  filters: Filters;
  sorting: Sorting;
};

export const initialState: State = {
  dataEntities: {
    items: null,
    total: null,
  },
  pagination: {
    page: 1,
    pageSize: 10,
  },
  filters: {
    dateRange: {
      fromDate: thirtyDaysAgo(),
      toDate: today(),
    },
    dataId: null,
    defId: null,
    runId: null,
  },
  sorting: {
    sortField: null,
    sortDirection: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(entities.actions.loadDataEntitiesSuccess, (state, { dataEntities }) => ({
    ...state,
    dataEntities,
  })),
  on(entities.actions.changeFilters, (state, { filters }) => ({ ...state, filters })),
  on(entities.actions.changePagination, (state, { pagination }) => ({ ...state, pagination })),
  on(entities.actions.changeSorting, (state, { sorting }) => ({ ...state, sorting })),
  on(entities.actions.resetFilters, (state) => ({ ...state, filters: initialState.filters })),
);

export const key = 'dataEntities';
