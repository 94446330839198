import { inject } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isEqual from 'lodash.isequal';
import { catchError, combineLatest, distinctUntilChanged, exhaustMap, filter, map, of } from 'rxjs';
import { transactions } from '.';
import { StateType } from '..';
import { AccountBalanceService } from '../../services/account-balance.service';
import { AccountOverviewsService } from '../../services/account-overviews.service';
import { TransactionService } from '../../services/transactions.service';
import { tenants } from '../tenants';

export const fetchTransactionsOnFilterChange = createEffect(
  (
    store: Store<StateType> = inject(Store<StateType>),
    transactionsService = inject(TransactionService),
  ) => {
    return combineLatest([
      store.select(tenants.selectors.selectSelectedTenant).pipe(filter((t) => t !== null)),
      store.select(transactions.selectors.selectFilters),
      store.select(transactions.selectors.selectPagination),
      store.select(transactions.selectors.selectSorting),
    ]).pipe(
      distinctUntilChanged(isEqual),
      exhaustMap(([selectedTenant, filters, pagination, sorting]) =>
        transactionsService
          .findTransactions(
            selectedTenant.id,
            filters,
            {
              page: pagination.page ?? undefined,
              pageSize: pagination.pageSize ?? undefined,
            },
            {
              sortDirection: sorting.sortDirection ?? undefined,
              sortField: sorting.sortField ?? undefined,
            },
          )
          .pipe(
            map((transactionsResult) =>
              transactions.actions.loadTransactionsSuccess({ transactions: transactionsResult }),
            ),
            catchError(() => of(transactions.actions.loadTransactionsFailure())),
          ),
      ),
    );
  },
  { functional: true },
);

export const loadAccountOverview = createEffect(
  (
    store: Store<StateType> = inject(Store<StateType>),
    accountOverviewService = inject(AccountOverviewsService),
  ) => {
    return combineLatest([
      store.select(tenants.selectors.selectSelectedTenant).pipe(filter((t) => t !== null)),
      store.select(transactions.selectors.selectFilters),
    ]).pipe(
      distinctUntilChanged(isEqual),
      exhaustMap(([selectedTenant, filters, pagination, sorting]) =>
        accountOverviewService.findAccountOverview(selectedTenant.id, filters).pipe(
          map((account) => transactions.actions.loadAccountOverviewSuccess({ account })),
          catchError(() => of(transactions.actions.loadAccountOverviewFailure())),
        ),
      ),
    );
  },
  { functional: true },
);

export const loadAccountBalance = createEffect(
  (
    store: Store<StateType> = inject(Store<StateType>),
    transactionsService = inject(AccountBalanceService),
  ) => {
    return combineLatest([
      store.select(tenants.selectors.selectSelectedTenant).pipe(filter((t) => t !== null)),
    ]).pipe(
      distinctUntilChanged(isEqual),
      exhaustMap(([selectedTenant]) =>
        transactionsService.findAccountBalance(selectedTenant.id).pipe(
          map((balance) => transactions.actions.loadAccountBalanceSuccess({ balance })),
          catchError(() => of(transactions.actions.loadAccountBalanceFailure)),
        ),
      ),
    );
  },
  { functional: true },
);
