import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable } from 'rxjs';
import { DefaultApiService } from '../../../generated/api';
import { transactions } from '../store/transactions';

export type AccountOverview = {
  tenantId: string | null;
  balance: number | null;
  contingent: number | null;
  usage: number | null;
  billing: number | null;
};

@Injectable({
  providedIn: 'root',
})
export class AccountOverviewsService {
  constructor(private apiService: DefaultApiService) {}

  findAccountOverview(
    tenantId: string,
    filters: transactions.Filters,
  ): Observable<AccountOverview> {
    const formatDate = (date?: NgbDate) =>
      date
        ? `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`
        : undefined;

    return this.apiService
      .overviewsTenantIdGet({
        tenantId,
        agents: filters.agents ?? undefined,
        types: filters.transactionTypes ?? undefined,
        fromTimestamp: filters?.dateRange?.fromDate
          ? formatDate(filters?.dateRange?.fromDate)
          : undefined,
        throughTimestamp: filters?.dateRange?.toDate
          ? formatDate(filters?.dateRange?.toDate)
          : undefined,
      })
      .pipe(map((response) => response.data));
  }
}
