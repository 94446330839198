import { createReducer, on } from '@ngrx/store';
import { alerts } from '.';

export type AlertIcon = 'success' | 'error';

export type Alert = { icon: AlertIcon; title: string; text: string };

export type State = {
  showAlert: Alert | null;
};

export const initialState: State = {
  showAlert: null,
};

export const reducer = createReducer(
  initialState,
  on(alerts.actions.showAlert, (state, { icon, title, text }) => ({
    ...state,
    showAlert: { icon, title, text },
  })),
);

export const key = 'alerts';
