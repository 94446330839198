import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, Observable, of } from 'rxjs';
import { DefaultApiService } from '../../../generated/api';
import { entities } from '../store/entities';

export interface DataEntity {
  id: string;
  dataflowDefinitionId: string;
  dataflowRunId: string;
  importedAt: string;
  dataId: string;
  payload?: any;
}
export interface DataEntities {
  items: DataEntity[] | null;
  total: number | null;
}
export interface SortingStateEntities {
  sortField?: 'dataflowDefinitionId' | 'dataflowRunId' | 'importedAt' | 'dataId';
  sortDirection?: 'asc' | 'desc';
}

@Injectable({
  providedIn: 'root',
})
export class EntitiesService {
  constructor(private apiService: DefaultApiService) {}

  findDataEntities(options: {
    tenantId: string;
    filters?: entities.Filters;
    pagination?: { page?: number; pageSize?: number };
    sorting?: SortingStateEntities;
  }): Observable<DataEntities> {
    return this.apiService
      .entitiesGet({
        tenantId: options.tenantId,
        dataIds: options?.filters?.dataId ? [options.filters?.dataId] : undefined,
        dataflowDefinitionIds: options?.filters?.defId ? [options.filters?.defId] : undefined,
        dataflowRunIds: options?.filters?.runId ? [options.filters?.runId] : undefined,
        fromTimestamp: this.dateFromToStr(options?.filters?.dateRange.fromDate),
        throughTimestamp: this.dateThroughToStr(options?.filters?.dateRange.toDate),
        page: options?.pagination?.page,
        pageSize: options?.pagination?.pageSize,
        sortDirection: options?.sorting?.sortDirection,
        sortField: options?.sorting?.sortField,
      })
      .pipe(
        map((response) => response.data),
        catchError(() => of({ total: 0, items: [] })),
      );
  }

  private dateFromToStr(date?: NgbDate | null) {
    const DELIMITER = '-';
    return date
      ? date.year.toString().padStart(4, '0') +
          DELIMITER +
          date.month.toString().padStart(2, '0') +
          DELIMITER +
          date.day.toString().padStart(2, '0') +
          'T00:00:00.000Z'
      : undefined;
  }
  private dateThroughToStr(date?: NgbDate | null) {
    const DELIMITER = '-';
    return date
      ? date.year.toString().padStart(4, '0') +
          DELIMITER +
          date.month.toString().padStart(2, '0') +
          DELIMITER +
          date.day.toString().padStart(2, '0') +
          'T23:59:59.000Z'
      : undefined;
  }
}
