import { createAction, props } from '@ngrx/store';
import { DataObjects } from '../../services/objects.service';
import { Filters, Pagination, Sorting } from './objects.reducer';

export const loadDataObjects = createAction('[DataObjects] Load DataObjects');

export const loadDataObjectsSuccess = createAction(
  '[DataObjects] Load DataObjects Success',
  props<{ dataObjects: DataObjects }>(),
);

export const loadDataObjectsFailure = createAction('[DataObjects] Load DataObjects Failure');

// Filter and Pagination Params
export const changeFilters = createAction(
  '[DataObjects] Change Filters',
  props<{ filters: Filters }>(),
);

export const resetFilters = createAction('[DataObjects] Reset Filters');

export const changePagination = createAction(
  '[DataObjects] Change Pagination',
  props<{ pagination: Pagination }>(),
);

export const changeSorting = createAction(
  '[DataObjects] Change Sorting',
  props<{ sorting: Sorting }>(),
);
