import { createAction, props } from '@ngrx/store';
import { anomalies } from '.';
import { Anomaly, AnomalyStream } from '../../services/anomalies.service';

// Anomaly Stream
export const loadAnomalyStream = createAction('[Anomalies] Load Anomaly Stream');

export const loadAnomalyStreamSuccess = createAction(
  '[Anomalies] Load Anomaly Stream Success',
  props<{ anomalyStream: AnomalyStream }>(),
);

export const loadAnomalyStreamFailure = createAction('[Anomalies] Load Anomaly Stream Failure');

// Filters
export const changeFilters = createAction(
  '[Anomalies] Change Filters',
  props<{ filters: anomalies.Filters }>(),
);

export const resetFilters = createAction('[Anomalies] Reset Filters');

// Pagination
export const changePagination = createAction(
  '[Anomalies] Change Pagination',
  props<{ pagination: anomalies.Pagination }>(),
);

export const resetPagination = createAction('[Anomalies] Reset Pagination');

// Status
export const patchAnomaly = createAction(
  '[Anomalies] Patch Anomaly',
  props<{ anomalyId: string; data: { hidden?: boolean; confirmed?: boolean | null } }>(),
);

export const patchAnomalySuccess = createAction(
  '[Anomalies] Patch Anomaly Success',
  props<{ anomaly: Anomaly }>(),
);

export const patchAnomalyFailure = createAction(
  '[Anomalies] Patch Anomaly Failure',
  props<{ anomaly: Anomaly }>(),
);
