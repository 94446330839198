import { createAction, props } from '@ngrx/store';
import { AccountOverview } from '../../services/account-overviews.service';
import { Transactions } from '../../services/transactions.service';
import { Filters, Pagination, Sorting } from './transactions.reducer';

export const loadTransactionsSuccess = createAction(
  '[Transactions] Load Transactions Success',
  props<{ transactions: Transactions }>(),
);

export const loadTransactionsFailure = createAction('[Transactions] Load Transactions Failure');

export const changeFilters = createAction(
  '[Transactions] Change Filters',
  props<{ filters: Filters }>(),
);

export const resetFilters = createAction('[Transactions] Reset Filters');

export const changePagination = createAction(
  '[Transactions] Change Pagination',
  props<{ pagination: Pagination }>(),
);

export const changeSorting = createAction(
  '[Transactions] Change Sorting',
  props<{ sorting: Sorting }>(),
);

export const loadAccountBalance = createAction('[Transactions] Load Account Balance');

export const loadAccountBalanceSuccess = createAction(
  '[Transactions] Load Account Balance Success',
  props<{ balance: number }>(),
);

export const loadAccountBalanceFailure = createAction(
  '[Transactions] Load Account Balance Failure',
);

export const loadAccountOverview = createAction('[Transactions] Load Account Overview');

export const loadAccountOverviewSuccess = createAction(
  '[Transactions] Load Account Overview Success',
  props<{ account: AccountOverview }>(),
);

export const loadAccountOverviewFailure = createAction(
  '[Transactions] Load Account Overview Failure',
);
